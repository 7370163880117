<script setup lang="ts">
const emit = defineEmits<{
  onSocialClick: [social: string]
}>()

const { currentWebsite } = useWebsite()

const paymentMethodsData = await usePaymentMethods()

const brandSocialData = await useBrandSocials(currentWebsite)

const filteredBrandSocial = computed(() => {
  return (brandSocialData?.value?.content?.socialItem ?? []).filter(
    _item => _item.social && _item.url
  )
})
const { isShoppable } = await useIsShoppable()
</script>
<template>
  <div class="flex flex-col justify-center lg:flex-row lg:justify-between">
    <div
      class="positive-padding gap-md gap-y-xs lg:py-lg py-md flex h-full flex-wrap items-center md:gap-y-0"
    >
      <span class="text-medium-6 block w-full md:w-auto lg:hidden">
        {{ $ts(`footer.follow`) }}
      </span>
      <ul
        class="gap-md sm:gap-y-xs flex flex-wrap items-center md:gap-y-0"
        aria-label="$ts('accessibility.socialLinks')"
      >
        <li
          v-for="(brandSocial, index) in filteredBrandSocial ?? []"
          :key="`${index}-${brandSocial.social}`"
          class="flex items-center"
        >
          <FooterSocialIcon
            v-if="brandSocial.social && brandSocial.url"
            :social="brandSocial.social"
            :url="brandSocial.url"
            @click="
              brandSocial.social && emit('onSocialClick', brandSocial.social)
            "
          />
        </li>
      </ul>
    </div>

    <ul
      v-if="isShoppable"
      class="positive-padding lg:py-lg gap-md py-md bg-primitives-off-white lg:bg-neutral-white flex h-full items-center overflow-auto"
      aria-label="$ts('accessibility.paymentMethods')"
    >
      <FooterPaymentMethodsIcon
        v-for="paymentMethod in paymentMethodsData?.content?.paymentMethods ??
        []"
        :key="paymentMethod"
        :icon="paymentMethod"
      />
    </ul>
  </div>
</template>
